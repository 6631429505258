.translation-desk{  
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 1em;  
  padding: 0 2em 2em;
  margin: 0 0 1em;
  position: relative;

  .label{
    position: relative;
    // left: 1em;
    // top: -1em;
    // background: white;
    // padding: 0.2em 2em;
    font-size: 1.2em;
    // border-radius: 1em;    
    display: flex;
    justify-content: space-between;    
    margin: 0.5em -0.8em 1.5em;
    align-items: center;
    border-bottom: 1px solid #eee;

    [role="group"]{
      margin: 0 0 0.5em;
    }
  }

  .english{
    background-color: rgba(0,0,50,0.1);
  }
  .translation{
    background-color: #c7cce8;
  }


  .translation-prompt{
    position: absolute;
    top: 0.1em;
    left: 1.5em;
    font-size: 2.5em;
    color: rgba(0,0,0,0.3);
    font-weight: 700;
  }

  .edit-display{
    background: white;
    padding: 0;
    margin: 1em 0;
    border-radius: 6px;      
    overflow-y: auto;
    max-height: 100%;
  }

  .edit-display-iframe{
    border: none;
    width: 100%;
    height: 50vh;
  }

  [data-ln1]::before {
    content: attr(data-ln1);
    position: sticky;
    top: 0;
    left: 0;
    background: black;
    color: white;
    width: 2em;
    height: 2em;
    display: flex;
    align-items:center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
    opacity: 0.4;
    z-index: 10;
  }

  .english{

  }

  [data-ln1]{
      position: relative;
      padding-left: 2em;
  }


 
  .editor-before{
    &[data-ln1]::before {
      right: 0;
      left: inherit;
    }
  } 

  // the editor
  .translation{

    > .MuiContainer-root{
      padding: 0;
    }
    .tox-tinymce{
      // remove border from editor
      border: none;
      margin: 1em 0;
      padding: 0;
      border-radius: 6px;      
    }

    .editor-button-bar{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}