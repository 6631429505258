.notifications-container{
  z-index: 2010;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  &.has-many-items {
    .messages{
      // border: 1px solid rgba(0,0,0,0.2);
      max-height: 10em;
      overflow-y: auto;
      background-color: rgba(255,255,255,0.4);
      box-shadow: 2px 2px 6px rgba(1,1,1,0.6);
    }

    .close-button {      
      width: auto;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 0em;
      top: -3em;
      z-index: 10;
      font-size: 0.6em;
    }
  }
  .messages{
    display: flex;
    flex-direction: column;    
    align-items: stretch;  
    position: relative;
    padding: 1em;
    border-radius: 1em 0 0 1em;
    transition: background-color 0.5s, box-shadow 0.5s;

    
    
    .MuiSnackbar-root{
      position: relative;
      bottom: inherit;
      margin-bottom: 0.4em;
      transform: translateX(-50%) scale(1);
      transition: 0.3s ease-out transform;
      &.closing{
        transform: translateX(-50%) scale(0.1);
      }
    }
  }
}