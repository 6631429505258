
$runAndCodeColor: #E40A74;
$playAndExploreColor: #43349F;

iframe.display-iframe{
    flex-grow: 1;
    margin: 1em 0;
    border-radius: 12px;
    // border: 1px solid gray;
    background: white;
    // limit the size of the iframe by target iframe width in app
    // max-width: 560px;
}

.editor-container{
    display: flex;
    flex-grow: 1;
    // limit the size of the iframe by target iframe width in app
    &.runandcode{
        width: calc(550px + 48px);
        height: calc(847px + 2em);
        padding: 1em 2em;
        background: $runAndCodeColor;

        .MuiContainer-root {
            margin: 0;
        }

        .tox.tox-tinymce {
            width: calc(550px);        
            height: 847px;
        }
    }
    &.playandexplore{        
        width: calc(1502px + 48px);
        height: 838px;
        padding: 1em 2em;
        background: $playAndExploreColor;

        // neutralize margin: auto in MUI container
        .MuiContainer-root {
            margin: 0;
        }

        .tox.tox-tinymce {
            width: calc(1502px);
            height: 838px;
        }
    }

    .editor-button-bar{
        margin: 1em 0;
        button {
            margin-right: 0.5em;
            background: rgba(255,255,255,0.4);
            &:hover{
                background: white;
            }
        }
    }     
}

.buttons{
    display: flex;
    flex-direction: row;
    margin: 0.4em 0;
    
    [type="button"]{
        margin-right: 0.5em;
    }
}