@import "../../variables.scss";

.languages{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  background: #ccc;
  padding: 2em;
  border-radius: 6px;
  
  .language-card{
    box-shadow: 0 0 0 rgba(0,0,0,0);
    background-color: $primaryColor;
    color: rgba(100, 200, 255,1 );
    font-size: 1.6em;
    font-weight: 300;
    transition: 0.5s all;
    &:hover{
      color: white;
      transform: translateY(-0.2em);
      box-shadow: 0.2em 0.2em 3px rgba(0,0,0,0.2);
    }
  }
}