@import "./variables.scss";

html,body {
  height: 100%;
  overflow: hidden;
}

body, .App{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

html body{
  overflow-y: auto;

  --info-text: rgb(5, 44, 128);

  h1, h1.MuiTypography-h1{
    font-size: 2em;
    color: $primaryColor;
  }

  

  .top-right{
    // top right corner of app bar
    a, button, svg{
      color: white;
      padding: 0.1em;
      min-width: 0; // remove min width from button and a

      @media screen and (max-width: $bp_mobile) {
        .MuiButton-label{
          span {
            display: none;
          }
        }
      }
      
    }
  }

  p.notify{
    font-size: 0.9em;
    margin: -1em 0 1em;
    display: flex;
    align-items: center;
    &.warning{
        color: darkred;
    }
    &.info{
        color: var(--info-text);
    }
  }

  // auto-breadcrumbs additional formatting
  .auto-breadcrumbs{
    ol{
      li{
        p{
          display: flex;
          align-items: center;
        }
        a{
          color: $primaryColor;
          text-decoration: none;
        }
      }
    }
  }
  
  .print-section{
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    display: block;
    z-index: 2000; // over everything
    background-color: white;
    border: none;
  }


  .cell-container{
    margin: -0.5em;
    display: flex;
    flex-wrap: wrap;
  }

  .gray-frame{
    background: rgba(0,0,0,0.1);
    padding: 1em;
    border-radius: 0.5em;
    margin: 1em 0;

    &.cell {
      margin: 0.5em;
    }
  }
 
  
}

.flex{
  display: flex;

  &.column{
    flex-direction: column;
  }
  &.row{
    flex-direction: row;
  }

  &.wrap{
    flex-wrap: wrap;
  }
  &.no-wrap{
    flex-wrap: nowrap;
  }

}

.logo-link{
  color: white;
  text-decoration: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  img{
    max-height: 2em;    
    margin-right: 0.3em;
  }

  @media screen and (max-width: $bp_mobile) {
    span {
      display: none;
    }
  }
}

.grow1{
  flex-grow: 1;
}


.items-center{
  align-items: center;
}
.items-end{
  align-items: flex-end;
}
.items-start{
  align-items: flex-start;
}
.items-stretch{
  align-items: stretch;
}

.content-start{
  justify-content: flex-start;
}
.content-center{
  justify-content: center;
}
.content-end{
  justify-content: flex-end;
}
.content-stretch{
  justify-content: stretch;
}

.content-space-between{
  justify-content: space-between;
}

#root{
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  // style nav button group
  .MuiButtonGroup-root{
    a{
      color: gray;
      &.active{
        background: aqua;
        color: darkblue;
      }

      // if it's a contained button - it's dark background, so we need to change the color
      &.MuiButton-contained {
        color: white;
    }
    }
  }

}

// Margins in em

$sides: top, right, bottom, left;


@for $offset from 0 to 21  {      
  .m-#{$offset}{
    margin: #{$offset * 0.1 }em;
  }
  
  @each $var in $sides {
    .m-#{$var}-#{$offset}{
      margin-#{$var}: #{$offset * 0.1 }em;
    }  

  }    

  @each $var in $sides {
    .m-#{$var}-#{$offset}-forced{
      margin-#{$var}: #{$offset * 0.1 }em!important;
    }  

  }    
}
  



.modal-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal-form{
    background: white;
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
    align-items: stretch;

    button{
      margin: 0.5em;
    }
  }
}