.form-dialog{
    div {
        margin: 0.5em 0;
    }

    .buttons{
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        margin-top: 1.5em;
        padding-top: 1em;
        border-top: 1px solid rgb(221, 221, 221);
    }
}

.buttons{
    display: flex;
    // flex-grow: 1;
    justify-content: space-between;         
}

.programs {

    h1 {
        margin: 0.5 0 2em;
        font-size: 1em;
    }

    .program-types-nav{
        margin: 0 0 1em;
    }

    .filter-item{
        display: flex;
        justify-content: flex-end;
        .count{
            font-size: 0.7em;
            color: white;
            margin-left: 0.5em;
            background: #999;    
            border-radius: 1em;
            padding: 0.2em 0.5em;
        }
    }
    
}

html {
    body{

        .filter-select{
            padding: 0.2em 1em;                   
        }
        .filter-item{
            display: flex;
            justify-content: flex-end;
            &::after{
                content: attr(data-count);
                font-size: 0.7em;
                color: white;
                margin-left: 0.5em;
                background: #999;    
                border-radius: 1em;
                padding: 0.2em 0.5em;
            }
        }
    }
}



