.max-width-400{
  max-width: 400px;
}
ul.credentials{  
  [role="menuitem"]{
    display: flex;    
    justify-content: space-between;
    
  }
  
}

.change-password-form, .user-form{
  display: flex;
  flex-direction: column;
}

.users .user{
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em;

  > div, >ul {
    flex-grow: 1;
    flex-basis: calc(30% - 1em);
    margin: 0.5em;
  }

  h3{
    margin: 0 0 0.5em;
    padding: 0 0 0.5em;
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }

  .detail-row{
    padding: 0.3em 0;
    border-bottom: 2px dotted rgba(0,0,0,0.1);
    span {
      &:first-child{
        font-weight: 600;
        width: 100px;

      }
    } 
  }
}



form{

  
  .error-message{
    color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 2.5em;  
    font-size: 0.85em;
  }
}


.user-profile{
  .detail-row{
    padding: 0.3em 0;
    border-bottom: 2px dotted rgba(0,0,0,0.1);
    span {
      &:first-child{
        font-weight: 600;
        width: 100px;

      }
    } 
  }
}