.login-form{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1{
        font-size: 1.2em;
    }

    .form-content > * {
        margin: 0.5em 0;

    }
}