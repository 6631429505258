.program-editor{

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .language-selector{
        display: flex;

        a.active{
            background-color: aqua;
        }
    }
}