html{
  body{
    #main {

      .section{
        & > h1{
          display: flex;
          justify-content: space-between;
        }
      }
    }
    [role="presentation"]{
      .content-items-list{
        max-height: 30vh;
        overflow-y: auto;
        margin: 0.5em 0;
        background: rgba(0,0,0,0.03);
        border-radius: 0.5em;
        .content-item{
          &.selected{
            background-color: aqua;
          }
        }
      }
    }
  }
}