@import "../../variables.scss";
#main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .dashboard{

        > ul.menu-block{
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: -1em;
    
            & > li{
                display: flex;
                flex-grow: 1;	
                min-height: 10em;
                flex-direction: column;
                padding: 1em;
                background-color: $primaryColor;
                margin: 1em;
                border-radius: 0.5em;
                color: white;
                a{
                    color: white;
                    text-decoration: none;
                    flex-grow: 1;
                }
                & > a {
                    color: #64c8ff;
                    font-size: 2.4em;
                    font-weight: 300;
                }

                & > ul {
                    
                }
                
            }
            ul {
                padding: 0;
                list-style: none;        
                flex-grow: 1;
                    > li{                        
                        display: flex;
                        flex-direction: column;
                        margin: 0;
                        padding-left: 0.5em;
                        a{                            
                            padding: 1em;
                            color: white;
                            text-decoration: none;
                            flex-grow: 1;

                            &:hover{
                                background-color: rgba(255,255,255,0.1);
                            }
                        }
                    }        
            }
        }
    }
}